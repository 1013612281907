import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { Form } from '@remix-run/react';
import { Button, TextInput } from '@tremor/react';
import { Label } from '~/components/ui/forms';
import { authenticator } from '~/services/auth.server';

export const meta: MetaFunction = () => {
  return [{ title: 'Ingresar' }];
};

export default function Login() {
  return (
    <div className="h-screen flex flex-col items-center justify-center">
      <h1 className="text-center text-4xl my-6">Cometa Admin</h1>

      <Form method="post">
        <div className="mb-5">
          <Label id="username">Correo electrónico</Label>
          <TextInput type="email" id="username" name="username" placeholder="name@getcometa.com" required />
        </div>
        <div className="mb-5">
          <Label id="password">Contraseña</Label>
          <TextInput
            type="password"
            id="password"
            name="password"
            autoComplete="current-password"
            placeholder="*****"
            required
          />
        </div>
        <div className="flex justify-center">
          <Button type="submit">Ingresar</Button>
        </div>
      </Form>
    </div>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  return await authenticator.authenticate('user-pass', request, {
    successRedirect: '/',
    failureRedirect: '/login',
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  return await authenticator.isAuthenticated(request, {
    successRedirect: '/',
  });
}
